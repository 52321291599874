<!-- eslint-disable prettier/prettier -->
<!-- 
    页面 用户管理 (这里的Table 用了 elemnt-ui)
-->
<template>
  <div id="people-list-page" class="data-list-container dark-layout">
    <vx-card>
      <div class="table_height_container">
        <!-- 操作按钮 -->
        <el-row class="search-row" type="flex">
          <el-col :span="12">
            <el-select class="search-select" size="mini" v-model="selectOption.building_code" placeholder="请选择楼栋" @change="buildingChange">
              <el-option v-for="item in buildingArr" :key="item.index" :label="`${item.building_name}`" :value="item.space_code"></el-option>
            </el-select>
            <el-select class="search-select" size="mini" v-model="selectOption.unit_code" placeholder="请选择单元" @change="unitChange">
              <el-option v-for="item in unitArr" :key="item.index" :label="`${item.unit_name}`" :value="item.space_code"></el-option>
            </el-select>
            <el-select class="search-select" size="mini" v-model="selectOption.house_name" placeholder="请选择房间" @change="houseChange">
              <el-option v-for="item in houseArr" :key="item.index" :label="item.house_no" :value="item.house_no"></el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-button class="search-btn" size="mini" @click="searchAction">搜索</el-button>
            <el-input class="search-input" size="mini" v-model="selectOption.name_phone" placeholder="请输入姓名/手机号"></el-input>
          </el-col>
        </el-row>
        <!-- 整体表 -->
        <el-table class="table_theme" :data="peopleArr" ref="rolesTable" row-key="people_id" v-loading="loading" @row-click="toogleExpand">
          <!-- <el-table-column width="45" show-overflow-tooltip header-align="center" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.people_id==currentIndex">
                <el-button type="text" @click="toogleExpand(scope.row)">收起</el-button>
              </span>
              <span v-else>
                <el-button type="text" @click="toogleExpand(scope.row)">展开</el-button>
              </span>
            </template>
          </el-table-column> -->
          <el-table-column type="index" width="60" label="序号">
            <template slot-scope="scope">
              <span>{{ (pageOpt.currentPage - 1) * pageOpt.currentPageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="头像" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.head_pic == ''" style="width: 50px; height: 50px; background-color: #C0C4CC;"></div>
              <img v-else :src="prefixHost + scope.row.head_pic" style="width: 50px; height: 50px" />
            </template>
          </el-table-column>
          <el-table-column property="people_name" label="姓名" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ $cFun.encryptName(scope.row.people_name) }}</p>
            </template>
          </el-table-column>
          <el-table-column property="sex" label="性别" width="100" show-overflow-tooltip></el-table-column>
          <el-table-column property="age" label="年龄" width="100" show-overflow-tooltip></el-table-column>
          <el-table-column property="mobile_phone" label="手机号码" width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ $cFun.encryptPhone(scope.row.mobile_phone) }}</p>
            </template>
          </el-table-column>
          <el-table-column label="证件类型" width="100" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ _.find(IDTypeArr, { code: scope.row.certificate_type }) ? _.find(IDTypeArr, { code: scope.row.certificate_type }).name : '' }}</p>
            </template>
          </el-table-column>
          <el-table-column property="certificate_no" label="证件号码" min-width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>
                {{ _.find(IDTypeArr, { code: scope.row.certificate_type }) ? (_.find(IDTypeArr, { code: scope.row.certificate_type }).name == '身份证' ? $cFun.encryptIDNumber(scope.row.certificate_no, 1) : $cFun.encryptIDNumber(scope.row.certificate_no, 2)) : '' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="制卡状态" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ _.find(CardStatusArr, { code: Number(scope.row.cardstate) }) ? _.find(CardStatusArr, { code: Number(scope.row.cardstate) }).name : '' }}</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120" header-align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" class="primary" @click="checkPeopleInfo(scope.row.people_id)">详情</el-button>
              <el-button type="text" size="small" class="primary" @click="delPeople(scope.row.people_id)">注销</el-button>
              <el-button type="text" size="small" class="primary" @click="checkCardList(scope.row.certificate_no, scope.row.people_name)">卡包</el-button>
            </template>
          </el-table-column>
          <!-- 二级表格 -->
          <el-table-column type="expand" width="0">
            <template slot-scope="scope">
              <el-table :data="scope.row.peopleHouseArr" style="width: 100%" max-height="250" :header-cell-style="tableHeaderColor">
                <el-table-column prop="village_name" label="小区" align="center" />
                <el-table-column prop="building_name" label="楼栋" align="center" />
                <el-table-column prop="unit_name" label="单元" align="center" />
                <el-table-column prop="house_no" label="房间" align="center" />
                <!-- <el-table-column fixed="right" label="操作" width="100" header-align="center">
                  <template>
                    <el-button type="text" size="small" class="primary">解除人房关系</el-button>
                  </template>
                </el-table-column> -->
              </el-table>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="bottom mt-5 text-center">
          <el-pagination class="pagination" layout="total, sizes, prev, pager, next" prev-text="上一页" next-text="下一页" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageOpt.currentPage" :page-sizes="pageOpt.pageSizes" :page-size="pageOpt.currentPageSize" :pager-count="pageOpt.pageCount" :total="pageOpt.totalPageSize" :hide-on-single-page="pageOpt.hideSinglePage"></el-pagination>
        </div>
        <!-- 抽屉 -->
        <el-drawer :title="select_people_name + '-卡包'" class="cardListDrawer" :visible.sync="drawer" size="300px">
          <el-container>
            <el-main v-loading="drawerLoading">
              <div v-if="cardList.length == 0">
                <p>暂无数据</p>
              </div>
              <ul else class="infinite-list" :style="{ height: $store.state.windowHeight + 'px' }">
                <li v-for="(item, index) in cardList" :key="index" class="infinite-list-item">
                  <el-card class="box-card">
                    <div class="text item">
                      <p>{{ _.find(BindTypeArr, { code: item.binding_type }) ? _.find(BindTypeArr, { code: item.binding_type }).name : '' }}</p>
                      <p>{{ _.find(CardTypeArr, { code: item.card_type }) ? _.find(CardTypeArr, { code: item.card_type }).name + ':' + item.card_no : '' }}</p>
                      <p>有效时间:{{ item.expire_time }}</p>
                      <div class="bottom clearfix">
                        <!-- <el-button v-if="item.auth_status == 1" type="text" class="button" @click="EraseCardFlow()">注销</el-button> -->

                        <el-button v-if="item.auth_status == 2" type="text" class="button" @click="removeLostCard(item.card_no)">取消挂失</el-button>
                        <el-button v-else-if="item.auth_status == 1" type="text" class="button" @click="lostCard(item.card_no)">挂失</el-button>
                      </div>
                    </div>
                  </el-card>
                </li>
              </ul>
            </el-main>
          </el-container>
        </el-drawer>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 图片前缀
      prefixHost: this.$cFun.getUrlPrefix(),
      // 选中数据
      selected: [],
      select_people_name: '',
      select_certificate_no: '',
      // 渲染数据
      peopleArr: [],
      buildingArr: [],
      unitArr: [],
      houseArr: [],
      cardList: [],
      // 枚举
      relationReturnDataAll: [],
      IDTypeArr: [],
      CardStatusArr: [],
      CardTypeArr: [],
      BindTypeArr: [],
      // 加载状态
      height: this.$store.state.windowHeight,
      currentIndex: null,
      drawer: false,
      loading: false,
      drawerLoading: false,
      // 当前页
      pageOpt: {
        currentPage: 1,
        currentPageSize: 10,
        totalPageSize: 0,
        hideSinglePage: true,
        pageSizes: [10, 20, 30],
        pageCount: 5
      },
      // 搜索条件
      selectOption: {
        village_code: this.$store.state.auth.space.space_code || '',
        building_code: '',
        unit_code: '',
        house_name: '',
        house_code: '',
        name_phone: '',
        people_name: '',
        mobile_phone: '',
        pageindex: 1,
        pagesize: 10
      }
    }
  },
  watch: {
    'selectOption.name_phone'(val) {
      var reg = /^[0-9]*$/
      if (val == '') {
        this.selectOption.mobile_phone = ''
        this.selectOption.people_name = ''
      } else {
        if (reg.test(val)) {
          this.selectOption.mobile_phone = val
        } else {
          this.selectOption.people_name = val
        }
      }
    },
    '$store.state.auth.space.space_code'(val) {
      this.selectOption.building_code = ''
      this.selectOption.unit_code = ''
      this.selectOption.house_code = ''
      this.selectOption.house_name = ''
      this.unitArr = []
      this.houseArr = []
      this.selectOption.village_code = val
      this.getBuildingList()
      this.getPeopleList()
      this.getPeopleCount()
    },
    relationReturnDataAll(val) {
      let IDTypeArr = _.find(val, { using_type: 'db/people/zhengjianleixing' })
      let CardStatusArr = _.find(val, { using_type: 'db/card/card_status' })
      let CardTypeArr = _.find(val, { using_type: 'db/card/card_type' })
      let BindTypeArr = _.find(val, { using_type: 'db/card/binding_type' })
      this.CardStatusArr = CardStatusArr.extend
      this.IDTypeArr = IDTypeArr.extend
      this.CardTypeArr = CardTypeArr.extend
      this.BindTypeArr = BindTypeArr.extend
    }
  },
  methods: {
    checkCardList(certificate_no, people_name) {
      this.drawer = true
      this.select_people_name = people_name
      this.select_certificate_no = certificate_no
      this.getCardList(certificate_no)
    },
    async toogleExpand(row) {
      if (this.drawer) return false
      let $table = this.$refs.rolesTable
      // 匹配当前行
      this.peopleArr.map(item => {
        if (row.people_id != item.people_id) {
          $table.toggleRowExpansion(item, false)
        }
      })
      // 请求人房信息
      row['peopleHouseArr'] = await this.getPeopleHouseList(row.people_id)
      $table.toggleRowExpansion(row)
      // 存储当前的锚点
      this.currentIndex = row.people_id
    },
    // 列表序号
    rowClassName({ row, rowIndex }) {
      row.xh = rowIndex + 1 + (this.pageOpt.currentPage - 1) * this.pageOpt.currentPageSize
    },
    // 调整单页条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageOpt.currentPageSize = val
      this.getPeopleList()
    },
    // 调整当前页数
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageOpt.currentPage = val
      this.getPeopleList()
    },
    // 查看人员信息
    checkPeopleInfo(peopleID) {
      this.$router.push({ name: 'peopleFlowMain', params: { peopleID } })
    },
    // 请求接口
    async searchAction() {
      this.pageOpt.currentPage = 1
      this.getPeopleList()
      this.getPeopleCount()
    },
    async getCardList(certificate_no) {
      this.drawerLoading = true
      try {
        let param = {
          village_code: this.selectOption.village_code,
          certificate_no: certificate_no
        }
        let result = await this.$apis.ODC('获取卡列表', param)
        if (this.$apis.isOk(result)) {
          this.cardList = result.cardList
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
      this.drawerLoading = false
    },
    async getPeopleCount() {
      try {
        let param = this.selectOption
        let result = await this.$apis.ODC('获取住户列表个数', param)
        if (this.$apis.isOk(result)) {
          this.pageOpt.totalPageSize = result.count
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 递归添加 (改动小可以使用递归)
    recurisonMenu(array) {
      let arr = []
      for (let item of array) {
        // 改变数据格式
        arr.push(Object.assign({ hasChildren: true, level: 0 }, item))
      }
      return arr
    },
    // 注销人
    async delPeople(people_id) {
      try {
        let param = {
          village_code: this.selectOption.village_code,
          people_id: people_id
        }
        let result = await this.$apis.ODC('注销住户', param)
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, 'success', this.$apis.getResMessage(result))
          await this.getPeopleList()
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取人员信息
    async getPeopleList() {
      this.loading = true
      try {
        let param = this.selectOption
        ;(param['pageindex'] = this.pageOpt.currentPage), (param['pagesize'] = this.pageOpt.currentPageSize)
        let result = await this.$apis.ODC('获取住户列表', param)
        if (this.$apis.isOk(result)) {
          this.peopleArr = this.recurisonMenu(result.PeopleList)
          // this.pageOpt.totalPageSize = result.data.count;
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
      this.loading = false
    },
    // 楼栋被选择的时候
    async buildingChange(val) {
      this.selectOption.unit_code = ''
      this.selectOption.house_code = ''
      this.selectOption.house_name = ''
      this.houseArr = []
      this.unitArr = await this.getUnitList(val)
    },
    // 单元
    async unitChange(val) {
      this.selectOption.house_code = ''
      this.selectOption.house_name = ''
      this.houseArr = await this.getHouseList(val)
    },
    // 房间
    houseChange(val) {
      let obj = _.find(this.houseArr, { house_no: val })
      this.selectOption.house_code = obj.space_code
    },
    // 获取楼栋列表
    async getBuildingList() {
      try {
        let params = {
          villageCode: this.selectOption.village_code,
          pageNum: 1,
          pageSize: 999
        }
        let result = await this.$apis.ODC('获取楼栋列表', params)
        if (this.$apis.isOk(result)) {
          this.buildingArr = result.data.buildingList
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取单元列表
    async getUnitList(buildingCode) {
      try {
        let params = {
          buildingCode: buildingCode,
          pageNum: 1,
          pageSize: 999
        }
        let result = await this.$apis.ODC('获取单元列表', params)
        if (this.$apis.isOk(result)) {
          return result.data.unitList
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取房间列表
    async getHouseList(unitCode) {
      try {
        let params = {
          unitCode: unitCode,
          pageNum: 1,
          pageSize: 999
        }
        let result = await this.$apis.ODC('获取房屋列表', params)
        if (this.$apis.isOk(result)) {
          return result.data.houseList
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取住户人房关系
    async getPeopleHouseList(people_id) {
      try {
        let params = {
          village_code: this.selectOption.village_code,
          people_id: people_id
        }
        let result = await this.$apis.ODC('获取住户人房关系', params)
        if (this.$apis.isOk(result)) {
          return result.phlist
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取枚举值(全部)
    async getRelationReturnDataALL(usingTypeArr) {
      try {
        let params = {
          usingTypes: usingTypeArr
        }
        let result = await this.$apis.ODC('获取关系内容', params)
        if (this.$apis.isOk(result)) {
          return result.relationList
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    },
    // 挂失
    async lostCard(card_no) {
      try {
        let params = {
          card_no: card_no
        }
        let result = await this.$apis.ODC('挂失', params)
        if (this.$apis.isOk(result)) {
          this.getCardList(this.select_certificate_no)
          this.$cFun.showNotify(this.$vs.notify, 'success', this.$apis.getResMessage(result))
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 取消挂失
    async removeLostCard(card_no) {
      try {
        let params = {
          card_no: card_no
        }
        let result = await this.$apis.ODC('取消挂失', params)
        if (this.$apis.isOk(result)) {
          this.getCardList(this.select_certificate_no)
          this.$cFun.showNotify(this.$vs.notify, 'success', this.$apis.getResMessage(result))
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 注销 EraseCard
    async EraseCardFlow() {
      this.$cFun.showNotify(this.$vs.notify, 'danger', '待联调')
      // 确定设备在线
      this.readDeviceState()
      // 本地清卡
      this.eraseCard()
      // 远程清卡
      this.delCard()
    },
    // 读取设备状态
    async readDeviceState() {
      // try {
      //   let result = await this.$apis.ODC("读取设备状态", {});
      //   console.log(result);
      // } catch (err) {
      //   console.log(err);
      // }
    },
    // 本地清卡
    async eraseCard() {
      try {
        let result = await this.$apis.ODC('清卡', {})
        console.log(result)
      } catch (err) {
        console.log(err)
      }
    },
    // 删除卡和卡授权
    async delCard() {
      try {
        let result = await this.$apis.ODC('删除卡和卡授权', {})
        console.log(result)
      } catch (err) {
        console.log(err)
      }
    },
    // 修改table tr行的背景色
    tableRowStyle({ row, rowIndex }) {
      return 'background-color: pink'
    },
    // 修改table header的背景色
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: lightblue;color: #fff;font-weight: 500;'
      }
    }
  },
  async created() {
    // 请求数据
    this.getPeopleList()
    this.getPeopleCount()
    this.getBuildingList()
    this.relationReturnDataAll = await this.getRelationReturnDataALL(['db/people/zhengjianleixing', 'db/card/card_status', 'db/card/card_type', 'db/card/binding_type'])
  },
  components: {}
}
</script>

<style lang="scss" scope="scope">
#people-list-page {
  .search-row {
    margin-bottom: 5px;
    .search-select {
      margin-right: 5px;
    }
    .search-input {
      float: right;
      width: 200px;
      margin-right: 5px;
    }
    .search-btn {
      float: right;
      &:hover {
        background-color: rgba(var(--vs-primary), 0.1);
        border-color: rgba(var(--vs-primary), 0.1);
      }
    }
  }
  .table_theme {
    .el-table__expanded-cell[class*='cell'] {
      padding: 0px;
    }
    .el-table__body tr {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .table_height_container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 200px);
  }
  .el-button.is-disabled.el-button--text {
    text-decoration: line-through;
  }
  .cardListDrawer {
    top: 60px;
    .el-drawer__header {
      margin-bottom: 0px;
    }
    .box-card {
      margin-bottom: 10px;
      .item {
        position: relative;
        height: 100px;
        .bottom {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}
</style>
